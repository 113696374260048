import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { MdDelete } from "react-icons/md";
import PersistentDrawerLeft from "../Navigation/AdminNavigation";
import UserService from "../../Services/UserServices";
import DeleteDialog from "../../Components/DeleteDialog";

export default function UserList() {
  const [users, setUsers] = React.useState([]);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");
  const [editUserId, setEditUserId] = React.useState(null);
  const [newUserRole, setNewUserRole] = React.useState("");

  const fetchUser = () => {
    UserService.getUsers()
      .then((response) => {
        setUsers(response.data["hydra:member"]);
      })
      .catch((error) => {
        setError("ユーザの取得に失敗しました。もう一度お試しください。");
      });
  };

  React.useEffect(() => {
    fetchUser();
  }, []);

  const handleOpen = (userId) => {
    setUserId(userId);
    setDeletePopup(true);
  };

  const handleClose = () => {
    setDeletePopup(false);
  };

  const handleDelete = async () => {
    try{
      if (!userId) {
        return;
    }
    const response = await UserService.deleteUser(userId);
    setMessage("ユーザを削除しました。");
    fetchUser();
    setDeletePopup(false);
  }catch(e){
    setError("ユーザの削除に失敗しました。もう一度お試しください。");
  }finally{
    setTimeout(() => {
      setMessage("");
      setError("");
      
    }, 5000);
  }
 
  };

  const handleRoleChange = async (event) => {
    setNewUserRole(event.target.value);
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    try {
      // 新しい権限を更新したら 1 秒待ってから更新する
      await wait(1000);
      await UserService.updateUserRole(editUserId, event.target.value);

      fetchUser();
      setEditUserId(null);
      setNewUserRole("");
      setMessage("ユーザの権限を更新しました。");
    } catch (e) {
      setError("ユーザの権限の更新に失敗しました。もう一度お試しください。");
    } finally {
      setTimeout(() => {
        setMessage("");
        setError("");
      }, 5000);
    }
  };

  return (
    <div>
      <PersistentDrawerLeft>
        <TableContainer component={Paper}>
          {message && <div className="message">{message}</div>}
          {error && <div className="error-message-reset">{error}</div>}

          {users.length === 0 && (
            <div className="message">ユーザありません。</div>
          )}

          <Table sx={{ minWidth: 650 }} aria-label="user table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>ID</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>メール</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>権限</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: "1.1rem",
                      color: "purple",
                      fontWeight: "bold",
                    }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell sx={{ fontSize: "1.1rem" }}>{row.email}</TableCell>
                  <TableCell sx={{ fontSize: "1.1rem" }}>
                    {editUserId === row.id ? (
                      <>
                        <select value={newUserRole} onChange={handleRoleChange}>
                          <option value="">-</option>
                          <option value="ALL">全て</option>
                          <option value="USER">ユーザ管理</option>
                          <option value="THREAD_POST">スレッド 投稿管理</option>
                        </select>
                      </>
                    ) : (
                      <span
                        onClick={() => {
                          setEditUserId(row.id);
                          setNewUserRole(row.roles);
                        }}
                      >
                        {row.roles}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "1.1rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdDelete
                      onClick={() => handleOpen(row.id)}
                      color="red"
                      className="delete-icon"
                      size={30}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PersistentDrawerLeft>

      {deletePopup && (
        <DeleteDialog
          message="選択されたユーザを削除しますか？"
          closePopup={handleClose}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
}
