import axios from "axios";

const BaseUrl = () => {
        return `/api`;
};

const axiosInstance = axios.create({
    baseURL: BaseUrl(),
    withCredentials: true, 
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers['Content-Type'] = "application/ld+json";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
