import React from "react";
import { Link } from "react-router-dom";
import "./404.css"

const NotFound = () => {
    return (
        <div className="not-found">
        <h2>404</h2>

        <p>ページが見つかりません</p>

        <Link to="/">ホームに戻る</Link>
        
        </div>
    )
}

export default NotFound;