import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThreadList from "./views/thread/ThreadList";
import ThreadDetails from "./views/ThreadDetails/ThreadDetails";
import PostUrl from "./views/PostUrl";
import ProtectedRoute from "./ProtectedRoute";
import AdminThreadList from "./views/Admin/AdminThreadList";
import AdminThreadDetails from "./views/Admin/AdminThreadDetails";
import UserForm from "./views/Admin/UserCreate";
import { PasswordChange } from "./views/Admin/PasswordChnage";
import PasswordResetInput from "./views/PasswordResetInput";
import EmailVerify from "./views/EmailVerify";
import NotFound from "./views/404";
import UserInviation from "./views/Admin/UserInvitation";
import UserInvitationConformation from "./views/UserInvitationConformation";
import UserList from "./views/Admin/UserList";

const Admin = () => {
  return (
    <div>
      <h1>管理者</h1>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ThreadList />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="threads/:id" element={<ThreadDetails />} />
        <Route path="posts/:post_id" element={<PostUrl />} />
        
        {/* only can access by admin */}
        <Route path="/admin/threads" element={<ProtectedRoute><AdminThreadList /></ProtectedRoute>} />
        <Route path="/admin/threads/:id" element={<ProtectedRoute><AdminThreadDetails /></ProtectedRoute>} />
        <Route path="/admin/users/create" element={<ProtectedRoute><UserForm /></ProtectedRoute>} />
        <Route path="/admin/chnage_password" element={<ProtectedRoute><PasswordChange /></ProtectedRoute>} />
        <Route path="/admin/users/inviation" element={<ProtectedRoute><UserInviation /></ProtectedRoute>} />
        <Route path="/admin/users" element={<ProtectedRoute><UserList /></ProtectedRoute>} />

        {/* can not access from browser */}
        <Route path="/reset-password" element={<PasswordResetInput />} />
        <Route path="/verify-email" element={<EmailVerify />} />
        <Route path="/invitation" element={<UserInvitationConformation />} />


        {/* 404 */}
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App;
