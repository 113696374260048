import React, { useState } from "react";
import Modal from "react-modal";
import "./LoginForm.css";
import PasswordResetForm from "./PasswordResetForm";
import axiosInstance from "../../Services/instance";
import { setAuth, setUserUri } from "../../Services/AuthService";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const LoginForm = ({ isOpen, onRequestClose, setIsLoggedInUser, closeModal }) => {
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const login = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post("/login", formData);
      if (res.status === 200) {
        setAuth(true);
        setUserUri(res.data.userUri)
        setIsLoggedInUser(true);
        closeModal();
        setFormData({ email: "", password: "" });
        navigate("/admin/threads");
      }
    } catch (err) {
      setLoginError("メールアドレスまたはパスワードが正しくありません");
      setTimeout(() => {
        setLoginError("");
      }, 3000);
    }
  };

  const handlePasswordResetClick = () => {
    setShowPasswordReset(true);
  };

  const handleBackToLogin = () => {
    setShowPasswordReset(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {!showPasswordReset ? (
        <>
          <h2>管理者ログイン</h2>
          {loginError && (
            <div style={{ color: "red", fontSize: "0.9rem" }}>{loginError}</div>
          )}
          <form onSubmit={login}>
            <input
              type="email"
              name="email"
              placeholder="メールアドレス"
              value={formData.email || ""}
              onChange={handleChange}
              required
              autoComplete="email"
            />
            <input
              type="password"
              name="password"
              placeholder="パスワード"
              value={formData.password || ""}
              onChange={handleChange}
              required
              autoComplete="current-password"
            />
            <div className="login-cancle-btn">
              <button type="button" onClick={onRequestClose} className="close-button">
                閉じる
              </button>
              <button type="submit">ログイン</button>
            </div>
          </form>
          <div className="password-reset-link">
            <button onClick={handlePasswordResetClick}>パスワードを忘れた方</button>
          </div>
        </>
      ) : (
        <PasswordResetForm back={handleBackToLogin} onRequestClose={onRequestClose} />
      )}
    </Modal>
  );
};

export default LoginForm;
