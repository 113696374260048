import React, { useState, useRef } from "react";
import "./PostForm.css";
import PostService from "../../Services/PostServices";
import Alert from "../Alert";

const PostForm = ({ threadId, threadUrl, fetchPost }) => {
  const [postForm, setPostForm] = useState({});
  const [error, setErrors] = useState({});
  const [popup, setPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const submitPost = async (e) => {
    if (isLoading) return;

    e.preventDefault();

    if (validateForm()) {
      try {
        const postData = {
          ...postForm,
          threadUrl: threadUrl,
        };

        const imageFormData = new FormData();
        imageFormData.append("file", image);
        if (postData.username !== undefined) {
          imageFormData.append("username", postData.username);
        }
        if (postData.email !== undefined) {
          imageFormData.append("email", postData.email);
        }
        if (postData.content !== undefined) {
          imageFormData.append("content", postData.content);
        }
        setIsLoading(true);

        let response;
        if (image !== null) {
          response = await PostService.createPostWithImage(threadId, imageFormData);
        } else {
          response = await PostService.createPost(threadId, postData);
        }

        if (response.status === 201) {
          await fetchPost();
          setPostForm({});
          setErrors({});
          setImage(null);
          fileInputRef.current.value = null;
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.detail) {
          setErrorMessage(error.response.data.detail);
        }
        setPopup(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setPostForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 500000) {
      setErrorMessage("写真サイズが大きすぎます。500KB以下の写真を選択してください。");
      setPopup(true);
      return;
    }
    setImage(file);
  };

  const validateForm = () => {
    let newErrors = {};
    if ((!postForm.content || !postForm.content.trim()) && !image) {
      newErrors.content = "投稿内容または写真を入力してください。";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const clearImage = () => {
    setImage(null);
    fileInputRef.current.value = null;
  };

  return (
    <div className="post-form">
      {popup && <Alert message={errorMessage} closePopup={() => setPopup(false)} />}
      <form>
        <div className="post-username-email-id">
          <input
            type="text"
            value={postForm.username || ""}
            onChange={(e) => handleChange(e, "username")}
            placeholder="ユーザ名 (任意)"
          />
          <input
            type="email"
            value={postForm.email || ""}
            onChange={(e) => handleChange(e, "email")}
            placeholder="メールアドレス (任意)"
          />
        </div>

        <textarea
          style={{ marginLeft: "0" }}
          className="post-body"
          value={postForm.content || ""}
          onChange={(e) => handleChange(e, "content")}
          placeholder="ここに投稿を書いてください..."
          required
        />
        {error.content && (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              margin: "5px 0",
            }}
          >
            {error.content}
          </div>
        )}
        <div className="file-input">
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleImageChange}
          />
          {image && (
            <button className="clear-image" onClick={clearImage}>
              写真を削除
            </button>
          )}
        </div>

        {image && (
          <div className="select-image">
            <img src={URL.createObjectURL(image)} alt="post" />
          </div>
        )}

        <input
          className="post-submit"
          type="submit"
          value="投稿"
          onClick={submitPost}
        />
      </form>
    </div>
  );
};

export default PostForm;
