import React, { useState, useEffect } from "react";
import Layout from "./Navigation/Layout";
import UserService from "../Services/UserServices";
import { useNavigate } from "react-router-dom";
import NotFound from "./404";
import "./EmailVerify.css";

const EmailVerify = () => {
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showResendEmailBtn, setShowResendEmailBtn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isValidResetUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      setToken(token);
      return !!token;
    };

    setIsValidUrl(isValidResetUrl());
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const res = await UserService.emailVerify(token);
        if (res.status === 201) {
          setMessage(
            "メールアドレスの確認が完了しました。\nアカウントが正常に有効化されました。管理者ページにログイン可能です。"
          );
          setTimeout(() => {
            setMessage("");
            navigate("/");
          }, 10000);
        } else if (res.status === 200) {
          setMessage("すでに検証済みです。\n管理者ページにログイン可能です。");
          setTimeout(() => {
            setMessage("");
            navigate("/");
          }, 5000);
        }
      } catch (e) {
        if (e.response && e.response.data.detail) {
          setError(e.response.data.detail);
          setShowResendEmailBtn(true);
          setTimeout(() => {
            setError("");
          }, 10000);
        }
      }
    };
    if (isValidUrl && token) {
      checkToken();
    }
  }, [token, isValidUrl, navigate]);

  const resend = async () => {
    try {
      await UserService.resendEmail(email);
      setMessage("メールが再送信されました。");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (e) {
      if (e.response && e.response.data.detail) {
        setError(e.response.data.detail);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };

  return isValidUrl ? (
    <Layout>
      <div className="verify-email">
        <div className="verify-container">
          {message && <div className="message">{message}</div>}
          {error && <div className="error-message">{error}</div>}
        </div>

        {showResendEmailBtn && (
          <div className="resend-container">
            <p>メールの再送信希望の場合は メールアドレス記入し 送信してください</p>
            <form onSubmit={(e) => { e.preventDefault(); resend(); }}>
              <div className="resend-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="resend-btn">
                メールを再送信
              </button>
            </form>
          </div>
        )}
      </div>
    </Layout>
  ) : (
    <NotFound />
  );
};

export default EmailVerify;
