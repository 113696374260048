import React, { useState } from "react";
import "./ListHeader.css";
import "../Dialog/Dialog.css";
import ThreadDialog from "../Dialog/ThreadDialog";
import ThreadService from "../../Services/ThreadServices";
import Search from "./Search";

const ListHeader = ({setThreads, showAddButton = true }) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const onSubmit = async (formData) => {
    try {
      const response = await ThreadService.createThread(formData);
      if (response.status === 201) {
        window.location.reload();
      }
    }catch(error) {
      }
  };

  const handleSearch = async (searchTerm, searchBy) => {
    try {
      const fetchedData = await ThreadService.getThreads(searchTerm, searchBy);
      const fetchedThreads = fetchedData["hydra:member"];
      setThreads(fetchedThreads);
    } catch (error) {
    }
  };

  return (
    <div className="list-header">
      <div className="list-header-title">
        <p>スレッド一覧</p>
      </div>
      <div className="list-header-search">
        <Search onSearch={handleSearch} />
      </div>
      {showAddButton && <button onClick={
        togglePopup} className="thread-button" >スレッド作成</button>}
      {showPopup && (
        <div className="popup-container">
          <ThreadDialog
            closePopup={togglePopup}
            title="スレッド作成"
            onSubmit={onSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default ListHeader;
