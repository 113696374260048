import React, { useState, useEffect, useCallback } from "react";
import Layout from "../Navigation/Layout";
import Header from "../../Components/ThreadDetails/Header";
import ThreadService from "../../Services/ThreadServices";
import { useParams } from "react-router-dom";
import ListItems from "../../Components/ThreadDetails/ListItems";
import PostForm from "../../Components/ThreadDetails/PostForm";
import axiosInstance from "../../Services/instance";

const ThreadDetails = () => {
  const [thread, setThread] = useState(null);
  const [posts, setPosts] = useState([]);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 30;

  const fetchPost = async (page = 1) => {
    setLoading(true);
    try {
      const post = await axiosInstance.get(`/threads/${id}/posts?page=${page}&itemsPerPage=${itemsPerPage}`);
      const responseData = post.data["hydra:member"];
      const totalItems = post.data["hydra:totalItems"];
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(totalPages);
      setPosts((prevPosts) => {
        if (page === 1) {
          return responseData;
        } else {
          const existingIds = new Set(prevPosts.map((post) => post.serialNumber));
          const newPosts = responseData.filter((post) => !existingIds.has(post.serialNumber));
          return [...prevPosts, ...newPosts];
        }
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPost(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const fetchThread = async () => {
      try {
        const fetchedData = await ThreadService.getThreadById(id);
        setThread(fetchedData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchThread();
  }, [id]);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
    const threshold = document.documentElement.offsetHeight - 350;
    if (scrollPosition >= threshold && !loading && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [loading, currentPage, totalPages]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handlePostSubmit = async () => {
    // 現在のページのデータを再取得
    await fetchPost(currentPage);

    // ページ制限に達しているかどうかを確認
    if (posts.length >= itemsPerPage * currentPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Layout>
      {thread && (
        <Header
          data={{
            id: thread.id,
            title: thread.title,
            email: thread.email,
            created_at: thread.createdAt,
          }}
          path="/"
          setPosts={setPosts}
        />
      )}

      <div style={{ marginTop: "20px" }}>
        <ListItems posts={posts} />
      </div>
      <div style={{ marginTop: "20px" }}>
        {thread && (
          <PostForm
            threadId={id}
            threadUrl={thread["@id"]}
            fetchPost={handlePostSubmit}
            currentPage={currentPage}
          />
        )}
      </div>
      {loading && <p>Loading...</p>}
    </Layout>
  );
};

export default ThreadDetails;
