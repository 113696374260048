import React from "react";

const Dialog = ({ children }) => {

  const popUpStyle = {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    maxWidth: '600px',
    width: '100%',
  };
  const popUpContentStyle = {
      marginBottom: '20px'
  }
  
  return (
    <div style={popUpStyle}>
      <div style={popUpContentStyle}>{children}</div>
    </div>
  );
};

export default Dialog;
