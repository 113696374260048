import React, { useState, useEffect, useCallback } from "react";
import ThreadService from "../../Services/ThreadServices";
import ListHeader from "../../Components/ThreadList/ListHeader";
import ListItem from "../../Components/ThreadList/ListItem";
import PersistentDrawerLeft from "../Navigation/AdminNavigation";
import axiosInstance from "../../Services/instance";

const AdminThreadList = () => {
  const [threads, setThreads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 30;


  const fetchThreads = async (page) => {
    try {
      setLoading(true);
      const fetchedData = await axiosInstance.get(`/threads?page=${page}&itemsPerPage=${itemsPerPage}`);
      const fetchedThreads = fetchedData.data["hydra:member"];
      const totalItems = fetchedData.data["hydra:totalItems"];
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setThreads(prevThreads => {
        const existingIds = new Set(prevThreads.map(thread => thread.id));
        const newThreads = fetchedThreads.filter(thread => !existingIds.has(thread.id));
        return [...prevThreads, ...newThreads];
      });

      setTotalPages(totalPages);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = useCallback(() => {
    const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
    const threshold = document.documentElement.offsetHeight - 100; 
    if (scrollPosition >= threshold && !loading && currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  }, [loading, currentPage, totalPages]);

  useEffect(() => {
    fetchThreads(currentPage);
  }, [currentPage]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);



  const deleteThread = async (id) => {
    try {
      await ThreadService.deleteThread(id);
      fetchThreads(currentPage);
    } catch (error) {
    }
  };

  return (
    <PersistentDrawerLeft>
     <div className="thread-list-container">
        <ListHeader setThreads={setThreads} showAddButton={false} />
        <div className="thread-list">
          {threads.map((item) => (
            <ListItem
              isAdmin={true}
              key={item.id}
              data={{
                id: item.id,
                title: item.title,
                created_at: item.createdAt,
                email: item.email,
              }}
              onClick={() => deleteThread(item.id)}
            />
          ))}
        </div>
        {loading && <p>Loading...</p>}
      </div>
      
    </PersistentDrawerLeft>
  );
};

export default AdminThreadList;
