import axiosInstance from "./instance";

const UserService = {

    async submitUserForm(email, password){
        try {
            const response = await axiosInstance.post('users', {
                email: email,
                password: password,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    async passwordChange(email, currentPassword, newPassword, newPasswordConfirmation) {
        try {
            const response = await axiosInstance.put('users/change_password', {
                email: email,
                oldPassword: currentPassword,
                newPassword: newPassword,
                newPasswordConfirmation: newPasswordConfirmation,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    async passwordReset(email){
        try {
            const response = await axiosInstance.post('users/reset_password_send_email', {
                email: email,
            });
            return response;
        }
        catch (error) {
            throw error;
        }
    },

    async emailVerify(token) {
        try {
            const response = await axiosInstance.post('users/email_verification', {
                token: token,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    async resendEmail(email) {
        try {
            const response = await axiosInstance.post('users/email_verification_resend', {
                email: email,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    async passwordResetConfirmation(password, passwordConfirmation, token, userId){
        try {
            const response = await axiosInstance.post('users/reset_password_conformation', {
                password: password,
                passwordConfirmation: passwordConfirmation,
                token: token,
                userId: userId,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    async sendInvitation(user, email, role){
        try {
            const response = await axiosInstance.post('user_invitations', {
                inviter: user,
                inviteEmail: email,
                authority: role,
            });
            return response;
        } catch (error) {
            throw error;
        }

    },

    async userInvitationConformation(email, token, password, passwordConfirmation){
        try {
            const response = await axiosInstance.post('/user_invitations/conformation', {
                email: email,
                invitationToken: token,
                password: password,
                passwordConfirmation: passwordConfirmation,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    async getUserInvitationsList(){
        try{
            const response = await axiosInstance.get('/user_invitations');
            return response;
        }catch (error) {
            throw error;
        }
    },

    async getUsers(){
        try {
            const response = await axiosInstance.get('/users');
            return response;
        } catch (error) {
            throw error;
        }
    },

    
    async deleteUserInvitation(id){
        try {
            const response = await axiosInstance.delete(`/user_invitations/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    },

    async deleteUser(userId){
        try {
            const response = await axiosInstance.delete(`/users/${userId}`);
            return response;
        } catch (error) {
            throw error;
        }
    },

    async updateUserRole(userId, roles){
        try {
            const response = await axiosInstance.put(`/users/${userId}`, {
                authority: roles,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    }
export default UserService;