import React from 'react';
import {  Navigate } from 'react-router-dom';
import { getAuth } from './Services/AuthService';

const ProtectedRoute = ({ children }) => {

  const isLoggedIn = () => {
    const isAuth = getAuth();

    if(isAuth) {
      return true;
    }else{
      return false;
    }
  };
  return isLoggedIn()  ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
