import React from "react";
import BackButton from "./BackButton";
import "./Header.css";
import SearchPost from "./SearchPost";
import PostService from "../../Services/PostServices";

const Header = ({ data, path, setPosts }) => {

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  const handleSearch = async (searchTerm, searchBy) => {
    try {
      const fetchedData = await PostService.getPostByThreadId(data.id, 1, searchTerm, searchBy);
      const fetchedThreads = fetchedData["hydra:member"];
      setPosts(fetchedThreads);
    } catch (error) {
      console.error("Error fetching threads:", error);
    }
  } 

  // Detect URL from text and return a link if found
  const detectUrlfromText = (text) => {
    if (typeof text !== 'string') {
      return <h2>{text}</h2>;
    }
    const words = text.split(' ');
    return (
      <h2>
        {words.map((word, index) => {
          return word.match(URL_REGEX) ? (
            <a key={index} href={word} target="_blank" rel="noopener noreferrer">{word}</a>
          ) : (
            <span key={index}>{word} </span>
          );
        })}
      </h2>
    );
  };

  return (
    <div className="thread-details-header">
      <div className="back-button">
        <BackButton path={path} />
      </div>
      <div className="thread-details-header-content">
        {detectUrlfromText(data.title)}
        <div className="thread-details-header-details">
          <a href={`mailto:${data.email}`}>{data.email}</a> 
          <p>{formatDate(data.created_at)}</p>
        </div>
        <SearchPost onSearch={handleSearch} />
      </div>
    </div>
  );
};

export default Header;
