import React, { useState, useEffect } from "react";
import PersistentDrawerLeft from "../Navigation/AdminNavigation";
import UserInvitationTable from "../../Components/Admin/UserInvitationTable";
import "./UserInvitation.css";
import { getUserUri } from "../../Services/AuthService";
import UserService from "../../Services/UserServices";

const UserInvitation = () => {
  const userUri = getUserUri();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("THREAD_POST");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [userInvitations, setUserInvitations] = useState([]);

  const fetchUserInvitations = async () => {
    try {
      const response = await UserService.getUserInvitationsList();
      setUserInvitations(response.data["hydra:member"]);
    } catch (error) {}
  };

  useEffect(() => {
    fetchUserInvitations();
  }, []);

  const sendInvitation = async () => {
    try {
      await UserService.sendInvitation(userUri, email, role);
      setEmail("");
      setRole("THREAD_POST");
      setMessage("招待メールを送信しました。");
      fetchUserInvitations();
    } catch (error) {
      console.log(error);
      console.log(error.stack);
      if (error.response && error.response.data.detail) {
        setError(error.response.data.detail);
      }
    } finally {
      setTimeout(() => {
        setMessage("");
        setError("");
      }, 6000);
    }
  };

  return (
    <PersistentDrawerLeft>
      {message && <div className="message">{message}</div>}
      {error && <div className="error-message-reset">{error}</div>}

      <div className="user-invitation-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendInvitation();
          }}
        >
          <div>
            <label>メールアドレス</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>ユーザ権限</label>
            <select value={role} onChange={(e) => setRole(e.target.value)}>
              <option value="ALL">全て</option>
              <option value="USER">ユーザ管理</option>
              <option value="THREAD_POST">スレッド 投稿管理</option>
            </select>
          </div>
          <button type="submit">招待</button>
        </form>
      </div>

      {/* list user invitation */}
      <div className="user-invitation-list">
        <UserInvitationTable
          userInvitations={userInvitations}
          fetchData={fetchUserInvitations}
          setMessage={setMessage}
          setError={setError}
        />
      </div>
    </PersistentDrawerLeft>
  );
};

export default UserInvitation;
