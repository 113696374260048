import React, { useState } from "react";

const SearchPost = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("content");

  const searchStyle = {
    padding: "10px",
  };

  const inputStyle = {
    padding: "10px",
    margin: "10px 0",
    fontSize: "14px",
    borderRadius: "2px",
    width: "300px",
    height: "45px",
  };

  const selectStyle = {
    padding: "10px",
    margin: "10px 0",
    fontSize: "14px",
    borderRadius: "2px",
    width: "150px",
    height: "45px",
  };

  const btnStyle = {
    marginLeft: "10px",
    padding: "10px",
    margin: "10px 0",
    fontSize: "14px",
    borderRadius: "2px",
    border: "none",
    width: "100px",
    height: "45px",
    backgroundColor: "#007bff",
    color: "#fff",
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    onSearch(searchTerm, searchBy);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(searchTerm, searchBy);
    }
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  return (
    <div style={searchStyle}>
      <input
        style={inputStyle}
        type="text"
        placeholder={`検索 (${searchBy === 'content' ? 'コンテンツ' : searchBy === 'email' ? 'メールアドレス' : 'ユーザ名'})`}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <select value={searchBy} onChange={handleSearchByChange} style={selectStyle}>
        <option value="content">タイトル</option>
        <option value="email">メールアドレス</option>
        <option value="username">ユーザ名</option>
      </select>
      <button onClick={handleSearch} style={btnStyle}>
        検索
      </button>
    </div>
  );
};

export default SearchPost;
