import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NotFound from "./404";
import Layout from "./Navigation/Layout";
import UserService from "../Services/UserServices";

const UserInvitationConformation = () => {
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [decodedEmail, setDecodedEmail] = useState("");
  const [allValid, setAllValid] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  useEffect(() => {
    const isValidInvitationUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenAndEmail = urlParams.get("token");

      if (!tokenAndEmail) return false;

      const [tokenPart, emailPart] = tokenAndEmail.split("?email=");

      if (!tokenPart) return false;

      setToken(tokenPart);
      setEmail(emailPart);

      return !!tokenPart && !!emailPart;
    };

    setIsValidUrl(isValidInvitationUrl());
  }, []);

  useEffect(() => {
    const decodeEmail = () => {
      try {
        const decodedEmail = atob(email);
        setDecodedEmail(decodedEmail);
        return true;
      } catch (error) {
        return false;
      }
    };

    const checkTokenLength = () => {
      if (token && token.length >= 50) {
        return true;
      }
      return false;
    };

    if (isValidUrl) {
      const emailDecoded = decodeEmail();
      const tokenLengthValid = checkTokenLength();

      if (emailDecoded && tokenLengthValid) {
        setAllValid(true);
      } else {
        setAllValid(false);
      }
    }
  }, [isValidUrl, email, token]);

  if (!allValid) {
    return <NotFound />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      await UserService.userInvitationConformation(
        decodedEmail,
        token,
        password,
        passwordConfirmation
      );
      setMessage("ユーザ 登録が完了しました。ログインページに移動します。");
      setTimeout(() => {
        navigate("/");
      }, 6000);

    }catch(error){
      if (error.response && error.response.data.detail) {
        setError(error.response.data.detail);
      }
    }finally{
      setTimeout(() => {
        setMessage("");
        setError("");
      }, 6000);
    }
  };

  return (
    <Layout>
      <div className="password-reset-conf">
        <div className="password-reset-form">
          <h2>アカウント登録設定</h2>

          {message && <div className="message">{message}</div>}
            {error && <div className="error-message-reset">{error}</div>}

          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={decodedEmail}
              disabled
              readOnly
            />
            <br />

            <input
              type="password"
              placeholder="新規パスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <br />
            <input
              type="password"
              placeholder="新規パスワードの確認"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
            <br />
            <button className="password-send-btn" type="submit">
              送信
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default UserInvitationConformation;
