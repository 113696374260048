import React, { useState, useEffect } from 'react';
import UserService from '../Services/UserServices';
import "./PasswordResetInput.css";
import Layout from './Navigation/Layout';

const PasswordResetInput = () => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [token, setToken] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const isValidResetUrl = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const userId = urlParams.get('user_id');
            setToken(token);
            setUserId(userId);
            return !!token && !!userId; 
        };

        setIsValidUrl(isValidResetUrl());
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            await UserService.passwordResetConfirmation(password, passwordConfirmation, token, userId);
            setMessage("パスワードがリセットされました。ログインしてください。");
            setTimeout(() => {
                setMessage("");
                window.location.href = "/";
            }, 5000);

        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                setError(error.response.data.detail);

                setTimeout(() => {
                    setError("");
                },[5000]);
            }
        }
    };

    return isValidUrl ? (
        <Layout>
            <div className='password-reset-conf'>
                <div className='password-reset-form'>
                    <h2>パスワードリセット</h2>

                    {message && <div className="message">{message}</div>}
                    {error && <div className="error-message-reset">{error}</div>}

                    <form onSubmit={handleSubmit}>
                        <input
                            type="password"
                            placeholder="新規パスワード"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <br />
                        <input
                            type="password"
                            placeholder="新規パスワードの確認"
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            required
                        />
                        <br />
                        <button className='password-send-btn' type="submit">送信</button>
                    </form>
                </div>
            </div>
        </Layout>
    ) : (
        <div>
            <h2>404 Not Found</h2>
        </div>
    );
};

export default PasswordResetInput;
