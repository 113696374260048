import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import "./ListItems.css";
import { Link } from "react-router-dom";
import DeleteDialog from "../DeleteDialog";
import ModalImage from 'react-modal-image';

const ListItems = ({ posts, isAdmin = false, deletePost = null }) => {
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const NUMBER_REGEX = />>\s*(\d+)|>>\s*(\d+)|>>\s*(\d+)/g;


  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setDeletePopup(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeletePopup(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = () => {
    deletePost(deleteId);
    handleCloseDeleteDialog();
  };

  const detectUrlfromText = (text, parentPostIds) => {
    if (typeof text !== 'string') {
      return <p>{text}</p>;
    }
  
    const lines = text.split('\n');
    return lines.map((line, lineIndex) => {
      const words = line.split(/\s+/);
  
      return (
        <p key={lineIndex}>
          {words.map((word, index) => {
            if (URL_REGEX.test(word)) {
              return (
                <a key={index} href={word} target="_blank" rel="noopener noreferrer">{word}</a>
              );
              
            } else if (word.match(NUMBER_REGEX)) {
              const postIdMatch = word.replace(/>>\s*|\s*/g, '');

              if (parentPostIds && parentPostIds.find) {
                const parentPost = parentPostIds.find(p => p.serialNumber === parseInt(postIdMatch));

                if (parentPost) {
                  return (
                    <Link key={index} to={`/posts/${parentPost.id}`} target="_blank" rel="noopener noreferrer">{word}</Link>
                  );
                } else {
                  return <span key={index}>{word} </span>;
                }
              } else {
                return <span key={index}>{word} </span>;
              }
            } else {
              return <span key={index}>{word} </span>;
            }
          })}
        </p>
      );
    });
  };

  
  return (
    <div>
      {posts.map((post) => (
        <div key={post.id} className="post">
          <div className="post-id-delete">
            {isAdmin && (
              <button className="delete-button" onClick={() => handleDeleteClick(post.id)}>
                <MdDelete color="red" />
              </button>
            )}
          </div>
          <Link style={{ textDecoration: 'none', color: 'black' }} to={`/posts/${post.id}`}>
            <div className="post-id">
              <p>{post.serialNumber}</p>
            </div>
          </Link>
          <div className="post-content">
            {detectUrlfromText(post.content, post.parentPostIds)}
            <div className="post-date-email">
              <small> 登校日時 : {formatDate(post.createdAt)}</small>
              {post.email && (
                <a href={`mailto:${post.email}`}>{post.email}</a>
              )}
              {post.username && <small> 投稿者: {post.username}</small>}

              {post.posterId && <small> ID: {post.posterId}</small>}
            </div>

              {post.imageUrl.length > 0 && (
                <div className="post-image">
                <ModalImage
                small={post.imageUrl}
                large={post.imageUrl}
                hideDownload={true} 
                hideZoom={true}
              />
              </div>
              )}
          </div>
        </div>
      ))}
      {deletePopup && (
        <DeleteDialog 
          message="選択されたポストを削除しますか？"
          closePopup={handleCloseDeleteDialog}
          onDelete={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default ListItems;
