import React from "react";
import Button from "./Button/Button";

const Alert = ({ message, closePopup }) => {
    const popUpStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '400px', 
        maxWidth: '600px', 
        height: '200px',
        backgroundColor: 'white',
        border: '1px solid gray', 
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '20px',
    };

    const alertStyle = {
        marginBottom: '10px',
    };

    return (
        <div style={popUpStyle}>
            <div style={alertStyle}>{message}</div>
            <Button
                text="キャンセル"
                style={{
                    marginTop: '10px',
                    backgroundColor: "red",
                    color: "white",
                    padding: "8px 15px",
                    margin: "8px 0",
                    fontSize: "20px",
                    marginRight: "10px",
                    borderRadius: "10px",
                    width: "150px",
                }}
                onClick={closePopup}
            />
        </div>
    );
};

export default Alert;
