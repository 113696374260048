import Cookies from "js-cookie";

const IS_AUTHENTICATED = "isAuthenticated";

export function setUserUri(user) {
    Cookies.set("userUri", user, { expires: 1/24 , path: '/' });

}

export function getUserUri() {
    return Cookies.get("userUri");
}

export function removeUserUri() {
    Cookies.remove("userUri");
}

export function setAuth(value = true) {
    Cookies.set(IS_AUTHENTICATED, value.toString(), { expires: 1/24 , path: '/' });
}

export function getAuth() {
    const isAuth = Cookies.get(IS_AUTHENTICATED);
    
    return isAuth === "true";
}

export function removeAuth() {
    Cookies.remove(IS_AUTHENTICATED);
}
