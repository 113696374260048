import React from "react";
import NavigationBar from "./NavigationBar";
import "./NavigationBar.css";

const Layout = ({ children }) => {

  return (
    <div>
      <NavigationBar />
      <div className="navigation-layout">
        {children}
      </div>
    </div>
  );
};

export default Layout;
