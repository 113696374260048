import React from "react";
import "./Button.css";

const Button = ({ text, onClick, style }) => {
    const defaultButtonStyle = {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        fontSize: '20px',
        border: 'none',
        cursor: 'pointer',
        width: 'auto',
        marginRight: '10px',
    };

    // 二つのスタイルオブジェクトをマージする (後のスタイルが優先される)
    const buttonStyle = { ...defaultButtonStyle, ...style };

    return (
        // ダイナミックなスタイルを適用する
        <button onClick={onClick} style={{ ...buttonStyle }} >
            {text}
        </button>
    );
}

export default Button;
