import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { MdDelete } from "react-icons/md";
import DeleteDialog from "../../Components/DeleteDialog";
import UserService from "../../Services/UserServices";

export default function UserInvitationTable({
  userInvitations,
  fetchData,
  setMessage,
  setError,
}) {
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [invitationId, setUserId] = React.useState(null);

  if (userInvitations.length === 0) {
    return <div></div>;
  }

  const handleOpen = (userId) => {
    setUserId(userId);
    setDeletePopup(true);
  };

  const handleClose = () => {
    setDeletePopup(false);
  };

  const handleDelete = async () => {
    try {
      await UserService.deleteUserInvitation(invitationId);
      setDeletePopup(false);
      setMessage("ユーザ招待が削除されました。");
      fetchData();
    } catch (error) {
      setError("ユーザ招待の削除に失敗しました。もうの一度お試しください。");
    } finally {
      setTimeout(() => {
        setMessage("");
        setError("");
      }, 6000);
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                招待者
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                メール アドレス
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                招待日
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                権限
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                ステータス
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userInvitations.map((row) => (
              <TableRow
                key={row.id} // Use a unique key for each row
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontSize: "1.1rem",
                    color: "purple",
                    fontWeight: "bold",
                  }}
                >
                  {row.inviter}
                </TableCell>
                <TableCell sx={{ fontSize: "1.1rem" }}>
                  {row.inviteEmail}
                </TableCell>
                <TableCell sx={{ fontSize: "1.1rem" }}>
                  {new Date(row.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell sx={{ fontSize: "1.1rem" }}>
                  {row.authority.join(", ")}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.1rem",
                    color:
                      row.status === "pending"
                        ? "orange"
                        : row.status === "accepted"
                        ? "green"
                        : "",
                  }}
                >
                  {row.status}
                </TableCell>

                <TableCell>
                  <MdDelete
                    onClick={() => handleOpen(row.id)}
                    color="red"
                    className="delete-icon"
                    size={30}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {deletePopup && (
        <DeleteDialog
          message="選択されたユーザ招待を削除しますか？"
          closePopup={handleClose}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
}
