import React from "react";
import Button from "./Button/Button";

const DeleteDialog = ({ message, closePopup, onDelete }) => {
    const backdropStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    };

    const popUpStyle = {
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '20px',
        width: '90%',
        maxWidth: '500px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        textAlign: 'center',
    };

    const messageStyle = {
        marginBottom: '20px',
        fontSize: '18px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'space-around',
    };

    const buttonStyle = {
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const noButtonStyle = {
        ...buttonStyle,
        backgroundColor: 'red',
        color: 'white',
    };

    const yesButtonStyle = {
        ...buttonStyle,
        backgroundColor: 'blue',
        color: 'white',
    };

    const handleDelete = async () => {
        await onDelete()
        closePopup()
    }

    return (
        <div style={backdropStyle}>
            <div style={popUpStyle}>
                <div style={messageStyle}>{message}</div>
                <div style={buttonContainerStyle}>
                    <Button
                        text="いいえ"
                        style={noButtonStyle}
                        onClick={closePopup}
                    />
                    <Button
                        text="はい"
                        style={yesButtonStyle}
                        onClick={handleDelete}
                    />
                </div>
            </div>
        </div>
    );
};

export default DeleteDialog;
