import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

const backButton = {
    height: "50px",
    width: "50px",
    backgroundColor: "#669BC2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",

};

const linkStyles = {
    textDecoration: "none",
    color: "white"
};

const BackButton = ({ path }) => {
    return (
        <div style={backButton}>
        <Link to={path} style={linkStyles}>
            <IoMdArrowRoundBack size="2em" />
        </Link>
        </div>
    );
};

export default BackButton;
