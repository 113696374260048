import React, { useState, useEffect, useCallback } from "react";
import Header from "../../Components/ThreadDetails/Header";
import ThreadService from "../../Services/ThreadServices";
import { useParams } from "react-router-dom";
import PostService from "../../Services/PostServices";
import ListItems from "../../Components/ThreadDetails/ListItems";
import PersistentDrawerLeft from "../Navigation/AdminNavigation";
import axiosInstance from "../../Services/instance";

const AdminThreadDetails = () => {
  const [thread, setThread] = useState(null);
  const [posts, setPosts] = useState([]);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 30;

  useEffect(() => {
    const fetchThread = async () => {
      try {
        const fetchedData = await ThreadService.getThreadById(id);
        setThread(fetchedData);
      } catch (error) {
      }
    };
    fetchThread();
  }, [id]);

  const fetchPost = async (page) => {
    setLoading(true);
    try {
      const post = await axiosInstance.get(`/threads/${id}/posts?page=${page}&itemsPerPage=${itemsPerPage}`);
      const fetchedPosts = post.data["hydra:member"];
      const totalItems = post.data["hydra:totalItems"];
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(totalPages);
      setPosts((prevPosts) => {
        const existingIds = new Set(prevPosts.map((post) => post.serialNumber));
        const newPosts = fetchedPosts.filter((post) => !existingIds.has(post.serialNumber));
        return [...prevPosts, ...newPosts];
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPost(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const fetchThread = async () => {
      try {
        const fetchedData = await ThreadService.getThreadById(id);
        setThread(fetchedData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchThread();
  }, [id]);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
    const threshold = document.documentElement.offsetHeight - 300;
    if (scrollPosition >= threshold && !loading && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [loading, currentPage, totalPages]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const deletePost = async (id) => {
    try {
      await PostService.deletePost(id);
      fetchPost(currentPage);
    } catch (err) {
    }
  };

  return (
    <PersistentDrawerLeft>
      {thread && (
        <Header
          data={{
            title: thread.title,
            email: thread.email,
            created_at: thread.createdAt,
          }}
          path="/admin/threads"
          setPosts={setPosts}
        />
      )}

      <div
        style={{
          marginTop: "20px",
        }}
      >
        <ListItems posts={posts} isAdmin={true} deletePost={deletePost} />
      </div>
      <div
        style={{
          marginTop: "20px",
        }}
      ></div>
    </PersistentDrawerLeft>
  );
};

export default AdminThreadDetails;
