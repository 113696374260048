import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavigationBar.css";
import LoginForm from "../../Components/Login/LoginForm";
import axiosInstance from "../../Services/instance";
import { setAuth, getAuth, removeAuth } from "../../Services/AuthService";

const NavigationBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [isAdminPage, setIsAdminPage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isAuth = getAuth();
    setIsLoggedInUser(isAuth);
  }, []);

  useEffect(() => {
    setIsAdminPage(location.pathname.startsWith("/admin"));
  }, [location.pathname]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const logout = async () => {
    try {
      await axiosInstance.post("/logout");
      setIsLoggedInUser(false);
      removeAuth();
      navigate("/");
    } catch (e) {
      console.log("Logout error:", e);
    }
  };

  const isResetPasswordPage = location.pathname.startsWith("/reset-password") || location.pathname.startsWith("/verify-email") || location.pathname.startsWith("/invitation");

  return (
    <>
      <div className="navbar">
        <div className="logo">
          <h1>OpenThread</h1>
        </div>
        <div className="nav">
          <div className="navbar-links">
            <ul>
              {!isResetPasswordPage && (
                <li>
                  {isLoggedInUser ? (
                    isAdminPage ? (
                      <div className="nav-admin">
                        {location.pathname !== "/admin/users/create" && (
                          <Link to="/">戻る</Link>
                        )}
                        {location.pathname === "/admin/users/create" && (
                          <Link to="/admin/threads">スレッド一覧</Link>
                        )}
                        <Link to="/admin/users/create">ユーザー作成</Link>
                      </div>
                    ) : (
                      <Link to="/admin/threads">管理者</Link>
                    )
                  ) : (
                    <button className="popup-open-bnt" onClick={openModal}>
                      管理者ログイン
                    </button>
                  )}
                </li>
              )}
              {isAdminPage && isLoggedInUser && (
                <li>
                  <button onClick={logout} className="logout-button">
                    ログアウト
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <LoginForm
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        setIsLoggedInUser={setIsLoggedInUser}
        closeModal={closeModal}
        
      />
    </>
  );
};

export default NavigationBar;
