import React, {useState} from "react";
import Modal from "react-modal";
import "./LoginForm.css";
import UserService from "../../Services/UserServices";

const PasswordResetForm = ({ back, onRequestClose }) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = async (e) =>{
        e.preventDefault();
        try{
            const res = await UserService.passwordReset(email);
            setMessage("パスワードリセット用のリンクを送信しました。メールを確認してください。");
            setTimeout(() => {
              onRequestClose();
              setError("");
            },[5000]);

        }catch(e){
            if(e.response && e.response.data && e.response.data.detail){
                setError(e.response.data.detail);
            }
        }finally{
            setTimeout(() => {
                setError("");
                setMessage("");
            }, [5000]);
        }
    }

  return (
    <Modal isOpen={true}>
      <h2>パスワードリセット</h2>

      {message && <div className="message">{message}</div>}
        {error && <div className="error-message-reset">{error}</div>}

      <p className="password-rest-p">
        パスワードリセット用のリンクを送信します。登録されたメールアドレスを入力してください。
      </p>
      <form className="password-rest-form">
        <input
         onChange={(e) => setEmail(e.target.value)}
         type="email" 
         placeholder="メールアドレス" required />
      </form>

      <div className="password-reset-btn">

      <button className="close-button" onClick={back}>戻る</button>
      <button onClick={sendEmail}>送信</button>

      </div>

    </Modal>
  );
};

export default PasswordResetForm;
