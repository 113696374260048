import axiosInstance from './instance';

const BASE_URL = '/threads';

const ThreadService = {
  
  async getThreads(searchTerm = "", searchBy = "title") {
  try {
    const url = `${BASE_URL}?${searchBy}=${searchTerm}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
},

  async createThread(threadData) {
    try {
      const response = await axiosInstance.post(`${BASE_URL}`, threadData);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getThreadById(threadId) {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/${threadId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteThread(threadId) {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/${threadId}`,

      
    );
    return response;
  } catch (error) {
    throw error;
  }
}

};
export default ThreadService;
