import React, { useState } from "react";
import { FaList } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import "./ListItem.css";
import { Link } from "react-router-dom";
import DeleteDialog from "../../Components/DeleteDialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const ListItem = ({ isAdmin = false, data, onClick = null }) => {
  const truncatedTitle = data.title.substring(0, 150);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeletePopup(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeletePopup(false);
    setDeleteId(null);
  };

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    return formattedDate;
  };

  return (
    <div className="list">
      <div className="list-content">
        <div className="list-content-icon">
          {isAdmin ? (
            <Button onClick={() => handleDelete(data.id)} className="delete-btn">
              <MdDelete color="red" className="deleet-icon" size={25} />
            </Button>
          ) : (
            <FaList color="black" className="list-icon" />
          )}
        </div>
        <div className="list-content-title">
          {isAdmin ? (
            <Link to={`/admin/threads/${data.id}`}>
              <Typography variant="h6">{truncatedTitle}</Typography>
            </Link>
          ) : (
            <Link to={`/threads/${data.id}`}>
              <Typography variant="h6">{truncatedTitle}</Typography>
            </Link>
          )}

          <div className="list-content-details">
            <Typography variant="body2">
              {isAdmin ? (
                <Link to={`/admin/threads/${data.id}`}>{formatDate(data.created_at)}</Link>
              ) : (
                <Link to={`/threads/${data.id}`}>{formatDate(data.created_at)}</Link>
              )}
            </Typography>
            <Typography variant="body2">
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </Typography>
          </div>
        </div>
      </div>
      {deletePopup && (
        <DeleteDialog
          message="選択されたスレッドを削除しますか？"
          closePopup={handleCloseDeleteDialog}
          onDelete={() => onClick(deleteId)}
        />
      )}
    </div>
  );
};

export default ListItem;
