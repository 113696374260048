import axiosInstance from "./instance";

const BASE_URL_THREAD = "/threads";
const BASE_URL_POST = "/posts";

const PostService = {
    async getPostByThreadId(threadId, page = 1, searchTerm = "", searchBy = "content") {
        try {
            const response = await axiosInstance.get(
                `${BASE_URL_THREAD}/${threadId}${BASE_URL_POST}?page=${page}&${searchBy}=${searchTerm}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async createPost(threadId, post) {
        try {
            const response = await axiosInstance.post(
                `${BASE_URL_THREAD}/${threadId}${BASE_URL_POST}`,
                post
            );
            return response;
        } catch (error) {
            throw error;
        }
    },

    async createPostWithImage(threadId, data) {
        try {
            const response = await axiosInstance.post(
                `/post_images/${threadId}/threads`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    
    async postDetail(postId) {
        try {
            const response = await axiosInstance.get(`${BASE_URL_POST}/${postId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async deletePost(postId) {
        try {
            const response = await axiosInstance.delete(
                `${BASE_URL_POST}/${postId}`,
                
                
            );
            return response;
        } catch (error) {
            throw error;
        }
    },

}
export default PostService;
