import React, { useEffect, useState } from "react";
import Layout from "./Navigation/Layout";
import PostService from "../Services/PostServices";
import { useParams } from "react-router-dom";
import "./PostUrl.css";
import ModalImage from "react-modal-image";

const PostUrl = () => {
  const [post, setPost] = useState(null);
  const { post_id } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const fetchedPost = await PostService.postDetail(post_id);
        setPost(fetchedPost);
      } catch (error) {
      }
    };
    fetchPost();
  }, [post_id]);

  return (
    <Layout>
      <div>
        {post && (
          <div className="post-body">
            <div className="post-id">
              <small>{post.serialNumber}</small>
            </div>
            <div className="post-content">
              <p
                style={{
                  whiteSpace: "pre-wrap",
                  fontWeight: "500",
                  fontSize: "23px",
                }}
              >
                {post.content}
              </p>
              <div style={{ marginTop: "20px" }} className="post-date-email">
                <small> 登校日時 : {post.createdAt}</small>
                {post.email && (
                  <a href={`mailto:${post.email}`}>{post.email}</a>
                )}
                {post.username && <p>{post.username}</p>}
              </div>
              {post?.imagePaths.length > 0 && (
                <div className="post-image">
                  <ModalImage
                    small={post?.imagePaths}
                    large={post?.imagePaths}
                    hideDownload={true}
                    hideZoom={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {post && post.commentPosts.length > 0 && (
          <div className="comment-posts">
            {post.commentPosts.map((comment) => (
              <div key={comment.serialNumber} className="comment-post">
                <div className="post-id">
                  <small>{comment.serialNumber}</small>
                </div>
                <div className="comment-content">
                  <div>
                    <p>{comment.content}</p>
                    <small>登校日時 : {comment.createdAt}</small>
                    {comment.email && (
                      <a href={`mailto:${comment.email}`}>{comment.email}</a>
                    )}
                    {comment.username && <p>{comment.username}</p>}
                  </div>
                  {comment?.imagePaths.length > 0 && (
                    <div className="post-image">
                      <ModalImage
                        small={comment.imagePaths}
                        large={comment.imagePaths}
                        hideDownload={true}
                        hideZoom={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PostUrl;
