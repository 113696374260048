import React, { useState } from "react";
import PersistentDrawerLeft from "../Navigation/AdminNavigation";
import "./UserForm.css";
import UserService from "../../Services/UserServices";
import { removeAuth } from "../../Services/AuthService";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Services/instance";

export const PasswordChange = () => {
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const res = await axiosInstance.post("/logout");
      if (res.status === 200) {
        removeAuth();
        navigate("/");
      }
    } catch (e) {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const passwordChange = await UserService.passwordChange(
        email,
        currentPassword,
        newPassword,
        newPasswordConfirmation
      );
      if (passwordChange.status === 200) {
        setMessage("パスワードが変更されました!");
        setEmail("");
        setCurrentPassword("");
        setNewPassword("");
        setNewPasswordConfirmation("");

        setTimeout(() => {
            logout();
        }, [3000]);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.detail) {
        setError(e.response.data.detail);
      }
    } finally {
      setTimeout(() => {
        setError("");
        setMessage("");
      }, [3000]);
    }
  };



  return (
    <PersistentDrawerLeft>
      <div className="body">
        <div className="user-form" style={
            {
                marginTop: "20px",
                width: "400px",
            }
            
        }>
          <p>パスワード変更</p>
        </div>
        {message && <div className="message">{message}</div>}
        {error && <div className="error-message">{error}</div>}

        <form className="password-create-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>メールアドレス</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>現在のパスワード</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>新しいパスワード</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>新しいパスワードの確認</label>
            <input
              type="password"
              value={newPasswordConfirmation}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button">
            変更
          </button>
        </form>
      </div>
    </PersistentDrawerLeft>
  );
};
