import React, { useState } from "react";
import "./UserForm.css";
import UserService from "../../Services/UserServices";
import PersistentDrawerLeft from "../Navigation/AdminNavigation";

const UserForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
        setError("パスワードが一致しません");
        setTimeout(() => {
          setError("");
        }, [5000]);
      return;
    }

    try {
      await UserService.submitUserForm(email, password);
        setMessage("ユーザが作成されました。 メールを確認しアカウントを有効化してください。");
        setEmail("");
        setPassword("");
        setPasswordConfirmation("");
        setTimeout(() => {
          setMessage("");
        }, [5000]);
    } catch (err) {
      if (err.response.data.violations) {
        setError(err.response.data.violations[0].message);
      }
      if (err.response.data.detail) {
        setError(err.response.data.detail);
      }
      setTimeout(() => {
        setError("");
      }, [5000]);
    }
  };

  return (
    <PersistentDrawerLeft>
      <div className="body">
        <div className="user-form">
          <p>ユーザ作成</p>
        </div>
        {message && <div className="message">{message}</div>}
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="user-creaet-form">
          <div className="form-group">
            <label>メールアドレス</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>パスワード</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>パスワード確認</label>
            <input
              type="password"
              name="password_confirmation"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <button className="submit-button" type="submit">作成</button>
          </div>
        </form>
        <div className="description">
        </div>
      </div>
    </PersistentDrawerLeft>
  );
};

export default UserForm;
