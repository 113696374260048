import React, { useState } from "react";
import "./Search.css";

const Search = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("title_and_email");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    onSearch(searchTerm, searchBy);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(searchTerm, searchBy);
    }
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  return (
    <div className="search-container">
      <input
        className="search-input"
        type="text"
        placeholder={`検索 (${searchBy === 'title_and_email' ? 'タイトル & メール' : 'ポストタイトル'})`}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <select value={searchBy} onChange={handleSearchByChange} className="select-style">
        <option value="title_and_email">タイトル & メール</option>
        <option value="post">ポストタイトル</option>
      </select>
      <button onClick={handleSearch} className="search-btn">
        検索
      </button>
    </div>
  );
};

export default Search;
