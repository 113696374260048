import React, { useState } from "react";
import Button from "../Button/Button";
import "./Dialog.css";
import Dialog from "./Dialog";

const ThreadDialog = ({ closePopup, title, onSubmit }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.email || !formData.email.trim()) {
      newErrors.email = "メールアドレスを入力してください。";
    }
    if (!formData.title || !formData.title.trim()) {
      newErrors.title = "スレッドの内容を入力してください。";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
      closePopup();
    }
  };

  return (
    <Dialog>
        <h2>{title}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="メールアドレス"
            value={formData.email || ""}
            onChange={(e) => handleChange(e, "email")}
          />
          {errors.email && (
            <div
              style={{
                color: "red",
                fontSize: "12px",
                margin: "5px 0",
              }}
            >
              {errors.email}
            </div>
          )}
          <textarea
            className="thared-body"
            placeholder="スレッドの内容"
            rows={3}
            cols={30}
            value={formData.title || ""}
            onChange={(e) => handleChange(e, "title")}
          />
          {errors.title && (
            <div
              style={{
                color: "red",
                fontSize: "12px",
                margin: "5px 0",
              }}
            >
              {errors.title}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              text="キャンセル"
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "8px 15px",
                margin: "8px 0",
                fontSize: "20px",
                marginRight: "10px",
                borderRadius: "10px",
                width: "150px",
              }}
              onClick={closePopup}
            />

            <Button
              type="submit"
              text="投稿"
              style={{
                backgroundColor: "#5566A1",
                color: "white",
                padding: "8px 15px",
                margin: "8px 0",
                fontSize: "20px",
                marginRight: "10px",
                borderRadius: "10px",
                width: "150px",
              }}
            />
          </div>
        </form>
        </Dialog>
  );
};

export default ThreadDialog;
